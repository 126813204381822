import React from 'react'
import { Box, Heading, Paragraph, Button, Anchor } from 'grommet'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Catalog, ScheduleNew, ChatOption, Article, Phone } from 'grommet-icons'
import Hero from '../components/Hero'
import Layout from '../components/PageLayout'
import Slice from '../components/Slice'
import SEO from '../components/SEO'

const IndexPage = ({ data }) => (
  <Layout>
    <SEO />
    <Hero />
    <Slice
      border={{
        color: 'accent-3',
        size: 'medium',
      }}
    >
      <Heading alignSelf="center" level="3">
        Buy from our partner
      </Heading>
      <Button
        alignSelf="center"
        primary="true"
        label="Buy from our Bookshop page"
        href="https://bookshop.org/shop/OrganicBooks?site"
      />
      <Paragraph size="large">
        On Bookshop you can order any book, brand new and delivered to your
        door, and we get the proceeds! To search our in-store inventory, you can
        use{' '}
        <Anchor href="http://www.biblio.com/bookstore/organic-books-albuquerque?aid=BSCB3451349">
          Biblio
        </Anchor>
        . Or for audiobooks, use{' '}
        <Anchor href="https://libro.fm/howitworks?bookstore=organicbooks">
          Libro.fm
        </Anchor>
        , and select Organic Books as your store.
      </Paragraph>
      <Paragraph size="large">
        We sell books new and used, with a special focus on local authors. We
        also carry cards, journals, pens and more. Open 11 to 6 every day. We
        also offer sidewalk shopping and curbside pickup! Or{' '}
        <Anchor href="#contact">contact us</Anchor> and we'll help you get what
        you need.
      </Paragraph>
    </Slice>
    <Slice>
      <Heading alignSelf="center" level="3">
        Policies
      </Heading>
      <Box>
        <Paragraph size="large">
          We take used books for trade or donation, and buy books directly from local
          authors.
        </Paragraph>
      </Box>
      <Button
        alignSelf="end"
        href="/books"
        icon=<Catalog />
        label="Learn more"
      />
    </Slice>
    <Slice>
      <Heading alignSelf="center" level="3">
        Our Family
      </Heading>
      <Paragraph size="large">
        We're the Brewer family. We live in Nob Hill and we love it here. The
        only thing missing was a bookstore. So we built one.
      </Paragraph>
      <Button
        alignSelf="end"
        href="contact"
        icon=<ChatOption />
        label="About us"
      />
    </Slice>
  </Layout>
)

export default IndexPage

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  query todayQuery {
    file(relativePath: { eq: "not-a-painting.png" }) {
      childImageSharp {
        fluid(
          maxWidth: 1280
          duotone: { highlight: "#ffffee", shadow: "#770000", opacity: 20 }
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
